import { useQuery } from "@apollo/client";
import { ethers } from "ethers";
import { get } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newApiClient } from "src/clients";
import { LOGIN_PROVIDERS } from "src/constants";
import { END_STORAGE_DATE } from "src/constants";
import { GET_MEMBERSHIPS } from "src/graphql/queries";
import { useGetEns } from "src/hooks";
import { useWalletConnect } from "src/integrations/walletConnect";
import { useWalletExtension } from "src/integrations/walletExtension";
import { resetState } from "src/reducers/actions/web3-state";
import { setENS } from "src/reducers/actions/web3-state";
import { shortenEthAddress } from "src/utils";
import { url } from "src/utils";

import { LoadingScreen } from "../../components/loading-screen";
import { projectAuth } from "../../firebase";
import { useWeb3LinkingFetcher } from "./useWeb3LinkingFetcher";
import mask from "./utils";

export const AuthContext = React.createContext();

export function useAuth() {
  const { currentUser } = useContext(AuthContext);
  const userCreatedAfterStorage = useMemo(() => {
    const createdAt = get(currentUser, "createdAt");
    const m = moment(createdAt);

    return moment(m).isAfter(END_STORAGE_DATE);
  }, [currentUser]);

  return { ...useContext(AuthContext), currentUser, isNewUser: userCreatedAfterStorage };
}

export function useTeam() {
  const accountId = url.getAccountIdFromUrl();

  const memberships = useQuery(GET_MEMBERSHIPS, {
    client: newApiClient,
    skip: !projectAuth.currentUser
  });

  if (memberships.loading || memberships.error) {
    return [];
  }

  const teams = get(memberships, "data.getMemberships.memberships", []) || [];

  if (!teams.length) {
    return null
  }

  const team = teams.find(team => team.teamId === accountId);

  const migrationStatus = get(team, "migrationStatus") === 'FAILED' ? null : get(team, "migrationStatus");
  const createdAt = get(team, "createdAt");
  const m = moment(createdAt);
  const canUseStorage = moment(m).isAfter(END_STORAGE_DATE);

  return { ...team, canUseStorage, migrationStatus }
}

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const web3State = useSelector((state) => state.web3);
  const dispatch = useDispatch();
  const { isWeb3Linked, linkedData, isLinkingVerifiying, checkIsWeb3Linked } =
    useWeb3LinkingFetcher(currentUser?.id);

  const isWeb3Provider = currentUser?.providerData?.length === 0;

  const walletAddress =
    isWeb3Provider || isWeb3Linked ? linkedData?.address : null;

  const { name, avatar } = useGetEns({
    provider: web3State.web3Provider,
    address: walletAddress,
  });

  const isGithubProvider = currentUser?.providerData?.some(
    (provider) => provider?.providerId === LOGIN_PROVIDERS.GITHUB
  );
  const isEmailProvider = currentUser?.providerData?.some(
    (provider) => provider?.providerId === LOGIN_PROVIDERS.PASSWORD
  );

  const address =
    isWeb3Linked || isWeb3Provider ? web3State.accounts?.[0] : undefined;

  const displayName = shortenEthAddress(
    web3State.ens?.name ||
      currentUser?.username ||
      address ||
      currentUser?.email.split("@")[0]
  );

  const onWeb3Disconnect = useCallback(() => {
    if (isWeb3Provider || isWeb3Linked) {
      dispatch(resetState());
      projectAuth.signOut();
    }
  }, [isWeb3Provider, isWeb3Linked, dispatch]);

  const onWeb3AccountsChanged = useCallback(
    (accounts) => {
      if (
        (isWeb3Provider || isWeb3Linked) &&
        web3State.accounts?.length !== 0 &&
        accounts?.length === 0
      ) {
        dispatch(resetState());
        projectAuth.signOut();
      }
    },
    [isWeb3Provider, isWeb3Linked, web3State.accounts?.length, dispatch]
  );

  const walletConnect = useWalletConnect({
    onAccountsChanged: onWeb3AccountsChanged,
    onDisconnect: onWeb3Disconnect,
  });
  const walletExtension = useWalletExtension({
    onAccountsChanged: onWeb3AccountsChanged,
    onDisconnect: onWeb3Disconnect,
  });

  const initializeWeb3 = useCallback(async () => {
    try {
      await walletExtension.initialize(ethers.providers.Web3Provider);
    } catch (error) {
      console.error(error);
    }

    try {
      await walletConnect.initialize(ethers.providers.Web3Provider);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    projectAuth.onAuthStateChanged((user) => {
      let userModel = user;

      if (user) {
        userModel = {
          createdAt: user.metadata ? user.metadata.creationTime : null,
          email: user.email ? user.email : null,
          id: user.uid ? user.uid : null,
          username: user.displayName,
          individual: {
            description: null,
            firstname: null,
            lastname: null,
            profilePicUrl:
              user.providerData && user.providerData.length > 0
                ? user.providerData[0].photoURL
                : user.photoURL,
            websiteUrl: null,
          },
        };

        if (
          !userModel.username &&
          user.providerData &&
          user.providerData.length > 0
        ) {
          userModel.username = user.providerData[0].displayName;
        }

        if (userModel.username) {
          const displayName = userModel.username;
          const splitUsername = displayName.split(" ");
          if (splitUsername.length > 0) {
            const [firstName] = splitUsername;
            userModel.individual.firstname = firstName;
          }
          if (splitUsername.length > 1) {
            userModel.individual.lastname = splitUsername.slice(1).join(" ");
          }
        }

        userModel.providerData = user.providerData;
        userModel.multiFactor = user.multiFactor;
        userModel.emailVerified = user.emailVerified;
        userModel.twoFactorAuth = {
          enabled: false,
          phoneNumber: null,
        };

        if (
          user.multiFactor &&
          user.multiFactor.enrolledFactors &&
          user.multiFactor.enrolledFactors.length > 0
        ) {
          userModel.twoFactorAuth.enabled = true;
          const { phoneNumber } = user.multiFactor.enrolledFactors[0];
          userModel.twoFactorAuth.phoneNumber = phoneNumber;
          userModel.twoFactorAuth.phoneNumberMasked = mask(phoneNumber, 4);
        }
      }

      setCurrentUser(userModel);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (isWeb3Linked || isWeb3Provider) {
      initializeWeb3();
    }
  }, [initializeWeb3, isWeb3Linked, isWeb3Provider]);

  useEffect(() => {
    if (name || avatar) {
      dispatch(setENS({ name, avatar }));
    }
  }, [name, avatar, dispatch]);

  const updateUserDetails = ({ fullName, email }) => {
    const updatedUserModel = {
      individual: currentUser.individual,
    };

    if (fullName) {
      updatedUserModel.username = fullName;

      const displayName = fullName;
      const splitUsername = displayName.split(" ");

      if (splitUsername.length > 0) {
        const [firstName] = splitUsername;
        updatedUserModel.individual.firstname = firstName;
      }
      if (splitUsername.length > 1) {
        updatedUserModel.individual.lastname = splitUsername.slice(1).join(" ");
      } else {
        updatedUserModel.individual.lastname = null;
      }
    }

    if (email) {
      updatedUserModel.email = email;
    }

    setCurrentUser({
      ...currentUser,
      ...updatedUserModel,
    });
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        isGithubProvider,
        isEmailProvider,
        isWeb3Provider,

        displayName,
        isWeb3Linked,
        isLinkingVerifiying,
        linkedData,
        walletConnect,
        walletExtension,
        currentUser,
        loading,
        updateUserDetails,
        checkIsWeb3Linked,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
