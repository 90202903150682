import Typography from "@material-ui/core/Typography";
import { Trans } from "react-i18next";

import useStyles from "./styles";

const TopBanner = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.text}>
        <Trans
          i18nKey="topBanner.title"
          components={
            <a
              key="-"
              className={classes.link}
              href="https://fleek.xyz"
              target="_blank"
              rel="noopener noreferrer"
            >
              fleek.xyz
            </a>
          }
        />
      </Typography>
    </div>
  );
};

export default TopBanner;
